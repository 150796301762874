
export default {
    layout: 'login',
    async mounted() {
        await this.authenticate()
    },
    methods: {
        async authenticate() {
            const canAuthorize = this.$hasAuthCookies()

            if (canAuthorize) {
                window.location.replace('/Account-picker')
            } else {
                await this.$auth.loginWith('oidc')
            }
        },
    },
}
